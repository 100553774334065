import "bootstrap/scss/bootstrap-grid.scss";
import "swiper/swiper-bundle.css";
import "aos/dist/aos.css";
import Swiper, { Navigation, Pagination } from "swiper";
import AOS from "aos/dist/aos.js";

Swiper.use([Navigation, Pagination]);

document.addEventListener("DOMContentLoaded", () => {
  AOS.init({
    once: true,
    duration: 1200,
  });
});

const trustedUs = new Swiper(
  ".subpage__trusted-us .subpage__trusted-us-wrapper--desktop .swiper-container",
  {
    loop: true,
    speed: 1000,
    direction: "horizontal",
    spaceBetween: 10,
    slidesPerView: 5,
    slidesPerGroup: 5,
    //centeredSlides: true,

    // autoplay: {
    //   delay: 4000,
    //   disableOnInteraction: false
    // },

    // pagination: {
    //     el: '.subpage__trusted-us .subpage__trusted-us-wrapper--desktop .swiper-pagination',
    //     type: 'bullets',
    //     clickable: 'true',
    //     disableOnInteraction: false
    // },

    navigation: {
      nextEl:
        ".subpage__trusted-us .subpage__trusted-us-wrapper--desktop .subpage__trusted-us-navigation-btn--next",
      prevEl:
        ".subpage__trusted-us .subpage__trusted-us-wrapper--desktop .subpage__trusted-us-navigation-btn--prev",
    },
  }
);

const trustedUsMobile = new Swiper(
  ".subpage__trusted-us .subpage__trusted-us-wrapper--mobile .swiper-container",
  {
    loop: true,
    speed: 1000,
    direction: "horizontal",
    slidesPerView: "1",
    spaceBetween: 10,

    // autoplay: {
    //   delay: 4000,
    //   disableOnInteraction: false
    // },

    pagination: {
      el: ".subpage__trusted-us .subpage__trusted-us-wrapper--mobile .swiper-pagination",
      type: "bullets",
      clickable: "true",
      disableOnInteraction: false,
    },

    navigation: {
      nextEl:
        ".subpage__trusted-us .subpage__trusted-us-wrapper--mobile .subpage__trusted-us-navigation-btn--next",
      prevEl:
        ".subpage__trusted-us .subpage__trusted-us-wrapper--mobile .subpage__trusted-us-navigation-btn--prev",
    },
  }
);

(function ($, root, undefined) {
  $(function () {
    function formLabels() {
      $(".wpcf7 .form-input, .wpcf7 .form-textarea, .wpcf7 .form-select")
        .focus(function () {
          $(this).parent().parent().addClass("active");
        })
        .blur(function () {
          var cval = $(this).val();
          if (cval.length < 1) {
            $(this).parent().parent().removeClass("active");
          }
        });
    }

    formLabels();

    let lastScrollTop = 0;

    $(window).scroll(function () {
      let st = $(this).scrollTop();

      if (st < 200 || st > lastScrollTop) {
        $(".header").removeClass("fixed");
      } else if (st == lastScrollTop) {
      } else if (st < lastScrollTop) {
        $(".header").addClass("fixed");
      }

      lastScrollTop = st;
    });

    let pathname = window.location.pathname;
    let ajaxURL = "/wp-admin/admin-ajax.php";

    if (pathname.includes("gawlas")) {
      ajaxURL = "/gawlas/wp-admin/admin-ajax.php";
    }

    //lazyLoadFn();

    function paginationPosts() {
      $(".blog-page__posts #news .pagination .page-item").click(function () {
        const category_slug = $("#news .categories-list__item.active").data(
          "catslug"
        );
        const current = $(this).find("button").data("current");
        const goto = $(this).find("button").data("goto");

        $.ajax({
          type: "POST",
          url: ajaxURL,
          data: {
            action: "shows_posts_action",
            current: current,
            goto: goto,
            category_slug: category_slug,
          },

          success: function (data) {
            if (data) {
              //var parsedJSON = JSON.parse(data);
              console.log(data);
              $("#news .blog-page__posts-list").html(data);

              paginationPosts();
            }
          },
          error: function (response) {
            console.log("Error");
          },
        });
      });
    }

    paginationPosts();

    function postsCategories() {
      $("#news .categories-list__item").click(function () {
        const category_slug = $(this).data("catslug");

        $.ajax({
          type: "POST",
          url: ajaxURL,
          data: {
            action: "shows_posts_action",
            current: 0,
            goto: 1,
            category_slug: category_slug,
          },

          success: function (data) {
            if (data) {
              //var parsedJSON = JSON.parse(data);
              $("#news .blog-page__posts-list").html(data);
            }
          },
          error: function (response) {
            console.log("Error");
          },
        });
      });
    }

    postsCategories();

    function paginationKnowledge() {
      $(".blog-page__posts #knowledgebase .pagination .page-item").click(
        function () {
          const category_slug = $(
            "#knowledgebase .categories-list__item.active"
          ).data("catslug");
          const current = $(this).find("button").data("current");
          const goto = $(this).find("button").data("goto");

          $.ajax({
            type: "POST",
            url: ajaxURL,
            data: {
              action: "show_knowledge_posts_action",
              current: current,
              goto: goto,
              category_slug: category_slug,
            },

            success: function (data) {
              if (data) {
                //var parsedJSON = JSON.parse(data);
                console.log(data);
                $("#knowledgebase .blog-page__posts-list").html(data);

                paginationKnowledge();
              }
            },
            error: function (response) {
              console.log("Error");
            },
          });
        }
      );
    }

    paginationKnowledge();

    function knowledgeCategories() {
      $("#knowledgebase .categories-list__item").click(function () {
        const category_slug = $(this).data("catslug");
        console.log(category_slug);

        $.ajax({
          type: "POST",
          url: ajaxURL,
          data: {
            action: "show_knowledge_posts_action",
            current: 0,
            goto: 1,
            category_slug: category_slug,
          },

          success: function (data) {
            if (data) {
              //var parsedJSON = JSON.parse(data);
              $("#knowledgebase .blog-page__posts-list").html(data);
            }
          },
          error: function (response) {
            console.log("Error");
          },
        });
      });
    }

    knowledgeCategories();
  });
})(jQuery, this);

const lazyLoadFn = () => {
  $(".lazy").Lazy({
    effect: "fadeIn",
    visibleOnly: false,
    scrollDirection: "both",
    autoDestroy: true,
    afterLoad: function (element) {
      $(element).removeClass("lazy-loading lazy");
      $(".img-post-wrapper").css("opacity", 1);
    },
    onFinishedAll: function () {
      $(".lazy-loading").removeClass("lazy-loading lazy");
      $(".img-post-wrapper").css("opacity", 1);
    },
  });
};

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// We listen to the resize event
window.addEventListener("resize", () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

const html = document.querySelector("html");

const header = document.querySelector(".header");
const headerHamburger = document.querySelector(".header .hamburger");
const headerMenuPopup = document.querySelector(".header .menu-popup");

if (headerMenuPopup) {
  headerHamburger.addEventListener("click", () => {
    headerHamburger.classList.toggle("hamburger--open");
    headerMenuPopup.classList.toggle("active");
    header.classList.toggle("fixed-open");
  });
}

const menuMobileSubmenus = document.querySelectorAll(
  ".menu-popup__container--mobile .menu .menu-item-has-children"
);

// menuMobileSubmenus.forEach(el => {
//   el.addEventListener('click', (e) => {
//     if(e.target != e.currentTarget) return;

//     e.currentTarget.classList.toggle('active');
//   });
// })

function teamMembersPopupInit() {
  const singleTeamMember = document.querySelectorAll(
    ".team-page .subpage__team-single"
  );
  const teamPopup = document.querySelector(".team__popup");
  const teamPopupContent = document.querySelector(".team__popup-content");
  const teamPopupWrapper = document.querySelector(".team__popup-wrapper");

  singleTeamMember.forEach((el) => {
    el.addEventListener("click", (e) => {
      var yScrollWidth2 = window.innerWidth - document.body.clientWidth + "px";
      html.style.paddingRight = yScrollWidth2;
      html.classList.add("popup--open");
      document.querySelector(".header").classList.remove("fixed");

      let id = e.currentTarget.dataset.id;
      id = id.replace("team-member-", "");

      let teamMembersData = teamMembersDataArray[id];
      let name = teamMembersData.name;
      let position = teamMembersData.position;
      let description = teamMembersData.description;

      let image = teamMembersData.image;
      let image_popup = teamMembersData.image_popup;
      let imageContent = "";

      if (image_popup) {
        imageContent = `<img src="${image_popup}" />`;
      } else if (image) {
        imageContent = `<img src="${image}" />`;
      }

      let linkedin = teamMembersData.linkedin;
      let linkedinContent = "";

      let hostname = window.location.hostname;
      let protocol = window.location.protocol;
      let pathname = window.location.pathname;

      let pageLink = "";
      if (pathname.includes("gawlas")) {
        pageLink = protocol + "//" + hostname + "/gawlas";
      } else {
        pageLink = protocol + "//" + hostname;
      }

      if (linkedin) {
        linkedinContent = `<a href="${linkedin}" class="team__popup-content-linkedin">
          <img src="${pageLink}/wp-content/themes/gawlas/img/team/linkedin.svg" />
        </a>`;
      }

      teamPopupContent.innerHTML = `
        <button type="button" class="team__popup-close-btn"></button>
        <div class="team__popup-content-col-left">
          <strong class="team__popup-content-name"> ${name}</strong>
          <span class="team__popup-content-position"> ${position}</span>
          ${linkedinContent}
          <div class="team__popup-content-img-wrapper">
            ${imageContent}
          </div>

        </div>

        <div class="team__popup-content-col-right">
          <div class="team__popup-content-description">
            ${description}
          </div>
        </div>
      `;

      const teamPopupClosebtn = document.querySelector(
        ".team__popup-close-btn"
      );

      teamPopupClosebtn.addEventListener("click", () => {
        teamPopupWrapper.classList.remove("active");

        setTimeout(() => {
          html.style.paddingRight = 0;
          html.classList.remove("popup--open");
        }, 300);
      });

      teamPopupWrapper.classList.add("active");
      let yScrollWidth = window.innerWidth - document.body.clientWidth + "px";
      html.style.paddingRight = yScrollWidth;
      html.classList.add("popup--open");
    });
  });
}

teamMembersPopupInit();

// function wonCasesPopupInit() {
//   const singleWonCase = document.querySelectorAll(".single-won-case");
//   const wonCasesPopup = document.querySelector(".won-cases__popup");
//   const wonCasesPopupContent = document.querySelector(
//     ".won-cases__popup-content"
//   );
//   const wonCasesPopupWrapper = document.querySelector(
//     ".won-cases__popup-wrapper"
//   );

//   singleWonCase.forEach((el) => {
//     el.addEventListener("click", (e) => {
//       var yScrollWidth = window.innerWidth - document.body.clientWidth + "px";
//       html.style.paddingRight = yScrollWidth;
//       html.classList.add("popup--open");
//       document.querySelector(".header").classList.remove("fixed");

//       let id = e.currentTarget.dataset.id;
//       id = id.replace("won-cases-", "");

//       let wonCasesData = wonCasesDataArray[id];
//       let name = wonCasesData.name;
//       let date = wonCasesData.date;
//       let description = wonCasesData.description;
//       let link = wonCasesData.link;

//       wonCasesPopupContent.innerHTML = `
//         <button type="button" class="won-cases__popup-close-btn"></button>
//         <div class="won-cases__popup-content-row-top">
//           <strong class="won-cases__popup-content-name"> ${name}</strong>
//           <a class="won-cases__popup-content-link" href="${link}" target="_blank">https://orzeczenia.nsa.gov.pl</a>
//           <span class="won-cases__popup-content-date"> ${date}</span>
//         </div>

//         <div class="won-cases__popup-content-row-bottom">
//           <div class="won-cases__popup-content-description">
//             ${description}
//           </div>
//         </div>
//       `;

//       const wonCasesPopupClosebtn = document.querySelector(
//         ".won-cases__popup-close-btn"
//       );

//       wonCasesPopupClosebtn.addEventListener("click", () => {
//         wonCasesPopupWrapper.classList.remove("active");

//         setTimeout(() => {
//           html.style.paddingRight = 0;
//           html.classList.remove("popup--open");
//         }, 300);
//       });

//       wonCasesPopupWrapper.classList.add("active");
//     });
//   });
// }

// wonCasesPopupInit();

const blogTabs = document.querySelectorAll(".blog-page__posts-tabs li");
const blogTabContentContainers = document.querySelectorAll(
  ".blog-page__posts-container"
);

if (blogTabs.length > 0) {
  blogTabs.forEach((el) => {
    el.addEventListener("click", (e) => {
      const id = e.currentTarget.dataset.id;
      const container = document.querySelector("#" + id);

      blogTabContentContainers.forEach((el) => {
        el.classList.remove("active");
      });

      container.classList.add("active");

      blogTabs.forEach((el) => {
        el.classList.remove("active");
      });

      e.currentTarget.classList.add("active");
    });
  });
}

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const postType = urlParams.get("type");

document.addEventListener("DOMContentLoaded", () => {
  if (postType) {
    blogTabs.forEach((el) => {
      const id = el.dataset.id;

      if (postType == id) {
        el.click();
        document.querySelector("#posts").scrollIntoView();
      }
    });
  }
});

/* Practice area */

const pacticeAreaTabs = document.querySelectorAll(
  ".practice-areas-page__content-tabs li"
);
const pacticeAreaLists = document.querySelectorAll(
  ".practice-areas-page__content-list-wrapper"
);
const pacticeAreaContentWrappers = document.querySelectorAll(
  ".practice-areas-page__content-single-wrapper"
);
const pacticeAreaContents = document.querySelectorAll(
  ".practice-areas-page__content-single"
);
const pacticeAreaListsItems = document.querySelectorAll(
  ".practice-areas-page__content-list li"
);

const pacticeAreaInfoBox = document.querySelector(
  ".practice-areas-page__content-info-box"
);
const pacticeAreaInfoBoxClose = document.querySelector(
  ".practice-areas-page__content-info-box-close"
);

pacticeAreaTabs.forEach((el) => {
  el.addEventListener("click", (e) => {
    const id = e.currentTarget.dataset.id;
    console.log(id); // law,

    pacticeAreaTabs.forEach((el) => {
      el.classList.remove("active");
    });

    e.currentTarget.classList.add("active");

    pacticeAreaLists.forEach((el) => {
      el.classList.remove("active");
    });

    const list = document.querySelector("#list-" + id);
    list.classList.add("active");

    // const firstLink = list.querySelectorAll(".single-item-link")[0];
    // setTimeout(function () {
    //   window.history.pushState("object", "New Title", firstLink.href);
    // }, 1000);

    pacticeAreaContentWrappers.forEach((el) => {
      el.classList.remove("active");
    });

    const content = document.querySelector("#content-" + id);
    content.classList.add("active");

    pacticeAreaInfoBox.classList.add("not-active");

    if (
      document.querySelector(
        ".practice-areas-page__content-list li.last.active.single-item-" + id
      )
    ) {
      pacticeAreaInfoBox.classList.remove("not-active");
    }
  });
});

pacticeAreaListsItems.forEach((el) => {
  el.addEventListener("click", (e) => {
    const id = e.currentTarget.dataset.id;
    const type = e.currentTarget.dataset.type;

    pacticeAreaListsItems.forEach((el) => {
      if (el.classList.contains("single-item-" + type)) {
        el.classList.remove("active");
      }

      if (el.classList.contains("last")) {
        pacticeAreaInfoBox.classList.add("not-active");
      }
    });

    if (el.classList.contains("last")) {
      pacticeAreaInfoBox.classList.remove("not-active");
    }

    e.currentTarget.classList.add("active");

    pacticeAreaContents.forEach((el) => {
      if (el.classList.contains("single-content-" + type)) {
        el.classList.remove("active");
      }
    });

    const content = document.querySelector("#content-" + id);
    content.classList.add("active");
  });
});

if (pacticeAreaInfoBox) {
  pacticeAreaInfoBoxClose.addEventListener("click", () => {
    pacticeAreaInfoBox.classList.toggle("hide");
  });
}

const practiceArea = urlParams.get("area");

if (practiceArea) {
  pacticeAreaTabs.forEach((el) => {
    const id = el.dataset.id;

    if (practiceArea == id) {
      el.click();
    }
  });
}

const contentAreasMobile = document.querySelectorAll(
  ".practice-areas-page__content-areas > li > span"
);
const contentAreasContentListElemsMobile = document.querySelectorAll(
  ".practice-areas-page__content-areas .content-list > li > span"
);
const contentAreasGoBackMobile = document.querySelectorAll(
  ".practice-areas-page__content-areas .content-wrapper > span"
);

contentAreasMobile.forEach((el) => {
  el.addEventListener("click", () => {
    const list = el.parentNode.querySelector(".content-list");
    list.classList.toggle("active");

    el.parentNode.classList.toggle("active");
  });
});

contentAreasContentListElemsMobile.forEach((el) => {
  el.addEventListener("click", () => {
    const contentWrapper = el.parentNode.querySelector(".content-wrapper");
    contentWrapper.classList.add("active");

    const contentList = el.parentNode.parentNode;
    contentList.classList.add("active-child");

    const contentGoBack = contentWrapper.querySelector("span");
    const content = contentWrapper.querySelector(".content-single");

    const contentListHeight = contentList.offsetHeight;
    const contentGoBackHeight = contentGoBack.offsetHeight;

    const height = contentListHeight - contentGoBackHeight;

    content.style.height = height + "px";
  });
});

contentAreasGoBackMobile.forEach((el) => {
  el.addEventListener("click", () => {
    el.parentNode.classList.remove("active");

    const contentList = el.parentNode.parentNode.parentNode;
    contentList.classList.remove("active-child");
  });
});

/* RODO */

const rodoContents = document.querySelectorAll(".rodo-page__content-single");
const rodoAreaListsItems = document.querySelectorAll(
  ".rodo-page__content-list li"
);

rodoAreaListsItems.forEach((el) => {
  el.addEventListener("click", (e) => {
    const id = e.currentTarget.dataset.id;

    rodoAreaListsItems.forEach((el) => {
      el.classList.remove("active");
    });

    e.currentTarget.classList.add("active");

    rodoContents.forEach((el) => {
      el.classList.remove("active");
    });

    const content = document.querySelector("#content-" + id);
    content.classList.add("active");
  });
});

const rodoAreaListsItemsMobile = document.querySelectorAll(
  ".rodo-page__content-rodo-list li span"
);

rodoAreaListsItemsMobile.forEach((el) => {
  el.addEventListener("click", (e) => {
    e.currentTarget.parentNode.classList.toggle("active");
  });
});

/* BLOG */

const blogCategoriesNews = document.querySelectorAll(
  ".blog-page__posts #news .categories-list__item"
);

blogCategoriesNews.forEach((el) => {
  el.addEventListener("click", (e) => {
    blogCategoriesNews.forEach((el) => {
      el.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
  });
});

const blogCategoriesKnowledgebase = document.querySelectorAll(
  ".blog-page__posts #knowledgebase .categories-list__item"
);

blogCategoriesKnowledgebase.forEach((el) => {
  el.addEventListener("click", (e) => {
    blogCategoriesKnowledgebase.forEach((el) => {
      el.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
  });
});

/* Form files */

const fileCVInput = document.querySelector("#file-cv");
const fileLabel = document.querySelector("#file-label");

if (fileCVInput) {
  fileCVInput.addEventListener("change", (e) => {
    if (e.currentTarget.files[0]) {
      fileLabel.textContent = e.currentTarget.files[0].name;
      fileLabel.classList.remove("btn--gray");
      fileLabel.classList.add("btn--dark");
    } else {
      fileLabel.textContent = "Załącz plik";
      fileLabel.classList.add("btn--gray");
      fileLabel.classList.remove("btn--dark");
    }
  });
}

const fileAttachmentInput = document.querySelector("#file-attachments");

if (fileAttachmentInput) {
  fileAttachmentInput.addEventListener("change", (e) => {
    console.log("dsadsa");
    if (e.currentTarget.files[0]) {
      fileLabel.textContent = e.currentTarget.files[0].name;
      fileLabel.classList.remove("btn--gray");
      fileLabel.classList.add("btn--dark");
    } else {
      fileLabel.textContent = "Załącz dokumenty";
      fileLabel.classList.add("btn--gray");
      fileLabel.classList.remove("btn--dark");
    }
  });
}

// Handle changing url without page reaload

const allSingleLinks = document.querySelectorAll(".single-item-link");

if (allSingleLinks) {
  allSingleLinks.forEach((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();
      // window.history.pushState("object", "New Title", link.href);
    });
  });
}
